import { observer } from "mobx-react";
import React, { Component } from "react";
import PropTypes from "prop-types";

export default observer(
  class VariantPrice extends Component {
    static propTypes = {
      availability: PropTypes.object.isRequired,
      priceDisplay: PropTypes.object.isRequired,
      store: PropTypes.object
    };

    render() {
      let priceText, priceUndiscountedText, priceLocalCurrency, isDiscount;
      const { availability, priceDisplay, store } = this.props;
      const { displayGross } = priceDisplay;
      const variant = store.variant;
      const taxRate = availability.taxRate;
      if (!store.isEmpty) {
        // variant price
        isDiscount = variant.price.amount !== variant.priceUndiscounted.amount;
        priceText = `${variant.price.amountLocalized}`;
        priceUndiscountedText = `${
          (variant.priceLocalCurrencyUndiscounted || variant.priceUndiscounted)
            .amountLocalized
        }`;
        if (variant.priceLocalCurrency) {
          priceLocalCurrency = variant.priceLocalCurrency.amountLocalized;
        }
      } else {
        // if there's no variant, fall back to product price
        const { discount, priceRange, priceRangeUndiscounted, priceRangeLocalCurrency, discountLocalCurrency } = availability;
        isDiscount = discount && !!Object.keys(discount).length;
        priceText = `${priceRange.minPrice.amountLocalized}`;
        if (priceRangeLocalCurrency) {
          priceUndiscountedText = `${priceRangeLocalCurrency.minPrice.amountLocalized}`;
        }
        else {
          priceUndiscountedText = `${priceRangeUndiscounted.minPrice.amountLocalized}`;
        }
        if (discountLocalCurrency) {
          priceLocalCurrency = discountLocalCurrency.minPrice.amountLocalized;
        }
      }
      return (
        <h2 className="product__info__price">
          <span>{priceLocalCurrency || priceText}&nbsp;</span>
          {isDiscount && (
            <small className="product__info__price__undiscounted">
              {priceUndiscountedText}
            </small>
          )}
          {displayGross ? (
              <small>including {taxRate}% VAT</small>
            ) : (
              <small>excluding {taxRate}% VAT</small>
            )
          }
        </h2>
      );
    }
  }
);
